import * as React from "react"
import {  graphql, Link } from "gatsby"
import CurveHero from "./../components/CurveHero"
import Layout from "./../components/layout"
import Seo from "../components/seo"
import Gallery from "../components/gallery"
import VideoGallery from "../components/VideoGallery"
import PostLinks from "../components/PDFLinks"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import parse from "html-react-parser"

function Post({ data, pageContext }) {

  const content = data.wp.project.content[0]
  const stats = content.stats
  const ImgGalleryArray = content.imgGallery
  const VideoGalleryArray = content.videosRepeater
  const PDFArray = content.documents

  console.log("pdfs", content);
  console.log("videoGalleryArray: ", VideoGalleryArray);

  const Sidebar = () => {

    if (data.wp.project.sidebar === true) {
      return (
      <div className="sidebar-content">
        <div className="sidebar-row">
          <p class="sidebar-title"><bold>Location</bold></p>
          <p class="sidebar-span">{stats.location || "45km ENE Telfer, WA"}</p>
        </div>
        <div className="sidebar-row">
          <p class="sidebar-title"><bold>Client</bold></p>
          <p class="sidebar-span">{stats.client || "45km ENE Telfer, WA"}</p>
        </div>
        <div className="sidebar-row">
          <p class="sidebar-title"><bold>Commodity</bold></p>
          <p class="sidebar-span">{stats.commodity || "Gold-Copper"}</p>
        </div>
        <div className="sidebar-row">
          <p class="sidebar-title"><bold>Contract Duration</bold></p>
          <p class="sidebar-span">{stats.contract || "January 2021 - October 2022"}</p>
        </div>
        <div className="sidebar-row">
          <p class="sidebar-title"><bold>Scope</bold></p>
          <p class="sidebar-span">{stats.scope || "Site establishment, box-cut support, decline development, shotcrete and raiseboring services."}</p>
        </div>
        <div className="sidebar-row">
        <PostLinks pdfLinks={PDFArray} />
        </div>

      </div>
      )
    }
    else return
  }
  const Breadcrumbs = () => (
    <div className="breadcrumbs-ord">
    <div className="path">
          <Link to={data.wp.wpParent.node.uri}> {data.wp.wpParent.node.title}</Link>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
          <p>{pageContext.title}</p>
      </div>
  </div>
  )

  return (
    <div className="bg-white">
      <Layout>
        <CurveHero pageTitle={pageContext.title} location={stats.location} />
        <section>
          <div className="container mx-auto w-2/3">
          <Breadcrumbs />
            <div className="page-content py-4">
              <Sidebar/>
              <div className="page-content-inside">
              <Tabs>
                <TabList>
                  <Tab>Gallery</Tab>
    {VideoGalleryArray ? <Tab>Videos</Tab> : null}
                      </TabList>
                <TabPanel>
                  <Gallery images={ImgGalleryArray} />
                </TabPanel>
                <TabPanel>
                  <VideoGallery images={VideoGalleryArray} />
                </TabPanel>
              </Tabs>
                  <p className="font-sans leading-5 font-normal text-black py-6 leading-7">{ content.desc && parse(content.desc)}</p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export const Head = ({pageContext}) => <Seo title={pageContext.title} />

export const pageQuery = graphql`
  query ProjectQuery($slug: String!) {
    wp: wpPage(slug: {eq: $slug}) {
      project: ACFProjects {
        sidebar

        content: projectContent {
          ... on WpPage_Acfprojects_ProjectContent_TextAndImage {
            desc: text
            img: rightImage {
              src: sourceUrl
            }
            imgGallery: imageGallery {
              sourceUrl
            }
            stats: projectStats {
              location
              client
              commodity
              contract
              scope
            }
            videosRepeater {
              videos
            }
            documents {
              pdfLabel
              pdfLink
            }
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            title
            uri
          }
        }
      }
    }
  }
`
export default Post
