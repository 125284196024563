import React from "react"

const PDFLinks = ({ pdfLinks }) => {

  console.log("PDFLinks: ", pdfLinks);

  if(!pdfLinks) return null;

  return (
    <div className="pdfLinks">
        {pdfLinks.map(( item, index) => (

          <a key={index} href={item.pdfLink}> { item.pdfLabel }</a>

        ))}
    </div>
  )
}

export default PDFLinks
