import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImageGallery = ({ images }) => {

  if(!images) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <img key={index} src={image.sourceUrl} alt="" />
        ))}
      </Slider>
    </div>
  )
}

export default ImageGallery
