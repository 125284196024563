import React from "react"
const Video = ({ videoSrcURL, ...props }) => (

  <div className="video">
  <iframe 
    title="SingleVideo"
    width="100%"
    height="600"
    src={videoSrcURL}
  >
  </iframe>
  </div>
)
export default Video
