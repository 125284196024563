import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SingleVideo from './SingleVideo.js'

const VideoGallery = ({ images }) => {
  console.log("video gallery", images);
  if(!images) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div>
      <Slider {...settings}>
      {images.map((image, index) => (
        <SingleVideo key={index} videoSrcURL={image.videos} />
      ))}
            </Slider>
    </div>
  )
}

export default VideoGallery
